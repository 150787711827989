.use-cookies-bar-container {
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: auto;
  margin: 0px;
  display: none;
  position: fixed;
  align-items: center;
  padding-left: 0px;
  border-radius: 21px;
  padding-right: 0px;
  flex-direction: row;
  background-color: rgba(250, 121, 48, 0.79);
}
.use-cookies-bar-container1 {
  flex: 0 0 auto;
  width: 80%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  flex-direction: row;
}
.use-cookies-bar-text {
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}
.use-cookies-bar-container2 {
  flex: 0 0 auto;
  width: 20%;
  display: flex;
  margin-top: 4px;
  align-items: flex-end;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 4px;
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.use-cookies-bar-button {
  height: 50px;
  align-self: flex-end;
  text-align: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
}
@media(max-width: 479px) {
  .use-cookies-bar-container1 {
    flex-direction: row;
  }
  .use-cookies-bar-container2 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}
