.home-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
}
.home-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-max-width {
  align-items: stretch;
}
.home-content-container {
  flex: 1;
  display: flex;
  min-width: 50%;
  align-items: flex-start;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: 0px;
  flex-direction: column;
  justify-content: center;
}
.home-text {
  text-align: left;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-link {
  display: contents;
}
.home-image {
  width: 200px;
  cursor: pointer;
  height: 59px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-threeunits);
  text-decoration: none;
}
.home-link1 {
  display: contents;
}
.home-image1 {
  width: 200px;
  cursor: pointer;
  height: 59px;
  object-fit: cover;
  text-decoration: none;
}
.home-image-container {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image2 {
  width: 100%;
  max-width: 589px;
  object-fit: cover;
  margin-left: 45px;
}
.home-section-one {
  border-color: rgba(33, 33, 33, 0.1);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-max-width1 {
  flex-direction: column;
}
.home-text01 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text02 {
  color: var(--dl-color-backgrounds-primary);
}
.home-cards-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-max-width2 {
  flex-direction: row;
  justify-content: flex-start;
}
.home-image-container1 {
  flex: 1;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.home-image3 {
  width: 100%;
  max-width: 640px;
  object-fit: cover;
  margin-left: -10px;
}
.home-content-container1 {
  flex: 1;
  display: flex;
  max-width: 446px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text06 {
  margin-bottom: 24px;
}
.home-text07 {
  color: var(--dl-color-grays-black100);
}
.home-text08 {
  color: var(--dl-color-backgrounds-primary);
}
.home-text09 {
  color: var(--dl-color-grays-gray100);
}
@media(max-width: 991px) {
  .home-max-width {
    flex-direction: column;
  }
  .home-content-container {
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .home-container1 {
    justify-content: center;
  }
  .home-image {
    margin-right: var(--dl-space-space-fiveunits);
  }
  .home-image2 {
    max-width: 100%;
    margin-left: 0px;
  }
  .home-image3 {
    max-width: 100%;
    margin-left: 0px;
  }
  .home-content-container1 {
    margin-bottom: 42px;
    padding-right: 0px;
  }
  .home-text06 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .home-text {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-container1 {
    align-items: center;
  }
  .home-image {
    margin-right: var(--dl-space-space-fourunits);
  }
  .home-text01 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-cards-container {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .home-max-width2 {
    flex-direction: column;
  }
  .home-image-container1 {
    width: 100%;
    margin-right: 0px;
  }
  .home-image3 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-content-container1 {
    max-width: 100%;
  }
}
@media(max-width: 479px) {
  .home-container1 {
    align-items: center;
    flex-direction: column;
  }
  .home-image {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-cards-container {
    justify-content: center;
  }
}
