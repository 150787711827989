.terms-os-use-page-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
}
.terms-os-use-page-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.terms-os-use-page-text {
  font-size: 25px;
  font-weight: 700;
}
.terms-os-use-page-text001 {
  font-style: normal;
  font-weight: 800;
}
.terms-os-use-page-container01 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.terms-os-use-page-text003 {
  font-size: 18px;
}
.terms-os-use-page-text004 {
  font-style: normal;
  font-weight: 300;
}
.terms-os-use-page-text005 {
  font-style: normal;
  font-weight: 300;
}
.terms-os-use-page-text006 {
  font-style: normal;
  font-weight: 300;
}
.terms-os-use-page-text007 {
  font-style: normal;
  font-weight: 300;
}
.terms-os-use-page-text008 {
  font-style: normal;
  font-weight: 300;
}
.terms-os-use-page-text009 {
  font-style: normal;
  font-weight: 300;
}
.terms-os-use-page-text010 {
  font-style: normal;
  font-weight: 300;
}
.terms-os-use-page-text011 {
  font-style: normal;
  font-weight: 300;
}
.terms-os-use-page-text012 {
  font-style: normal;
  font-weight: 300;
}
.terms-os-use-page-text013 {
  font-style: normal;
  font-weight: 300;
}
.terms-os-use-page-text014 {
  font-style: normal;
  font-weight: 300;
}
.terms-os-use-page-text015 {
  font-style: normal;
  font-weight: 300;
}
.terms-os-use-page-container03 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text016 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}
.terms-os-use-page-container04 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text017 {
  font-size: 18px;
}
.terms-os-use-page-text052 {
  font-size: 20px;
}
.terms-os-use-page-container05 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text053 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}
.terms-os-use-page-container06 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text054 {
  font-size: 18px;
}
.terms-os-use-page-container07 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text069 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}
.terms-os-use-page-container08 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text070 {
  font-size: 18px;
}
.terms-os-use-page-container09 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text080 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}
.terms-os-use-page-container10 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text081 {
  font-size: 18px;
}
.terms-os-use-page-container11 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text087 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}
.terms-os-use-page-container12 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text088 {
  font-size: 18px;
}
.terms-os-use-page-container13 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text089 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}
.terms-os-use-page-container14 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text090 {
  font-size: 18px;
}
.terms-os-use-page-container15 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text098 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}
.terms-os-use-page-container16 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text099 {
  font-size: 18px;
}
.terms-os-use-page-text109 {
  font-size: 20px;
}
.terms-os-use-page-container17 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text110 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}
.terms-os-use-page-container18 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text111 {
  font-size: 18px;
}
.terms-os-use-page-container19 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text119 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}
.terms-os-use-page-container20 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text120 {
  font-size: 18px;
}
.terms-os-use-page-container21 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text124 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}
.terms-os-use-page-container22 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text125 {
  font-size: 18px;
}
.terms-os-use-page-container23 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text137 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}
.terms-os-use-page-container24 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text138 {
  font-size: 18px;
}
.terms-os-use-page-container25 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text144 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}
.terms-os-use-page-container26 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.terms-os-use-page-text145 {
  font-size: 18px;
}
