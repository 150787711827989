.privacy-policy-page-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
}
.privacy-policy-page-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-page-text {
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
}
.privacy-policy-page-container01 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.privacy-policy-page-text001 {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-container03 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-text002 {
  font-size: 18px;
}
.privacy-policy-page-text007 {
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-text017 {
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-text022 {
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-container04 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-text037 {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-container05 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-text038 {
  font-size: 18px;
}
.privacy-policy-page-container06 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-text039 {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-container07 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-text040 {
  font-size: 20px;
}
.privacy-policy-page-text041 {
  font-size: 18px;
}
.privacy-policy-page-text042 {
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-text043 {
  font-size: 18px;
}
.privacy-policy-page-text046 {
  font-size: 18px;
}
.privacy-policy-page-text048 {
  font-size: 18px;
}
.privacy-policy-page-text052 {
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-text053 {
  font-size: 18px;
}
.privacy-policy-page-text056 {
  font-size: 18px;
}
.privacy-policy-page-text060 {
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-text067 {
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-container08 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-text072 {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-container09 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-text073 {
  font-size: 18px;
}
.privacy-policy-page-text074 {
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-text075 {
  font-size: 18px;
}
.privacy-policy-page-text078 {
  font-size: 18px;
}
.privacy-policy-page-text079 {
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-text080 {
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-text081 {
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-text082 {
  font-size: 18px;
}
.privacy-policy-page-text087 {
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-container10 {
  width: 100%;
  height: 21px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-text092 {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-container11 {
  width: 100%;
  height: 21px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-text093 {
  font-size: 18px;
}
.privacy-policy-page-text094 {
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-text095 {
  font-size: 18px;
}
.privacy-policy-page-text099 {
  font-size: 18px;
}
.privacy-policy-page-text101 {
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-text102 {
  font-size: 18px;
}
.privacy-policy-page-text105 {
  font-size: 18px;
}
.privacy-policy-page-text107 {
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-text108 {
  font-size: 18px;
}
.privacy-policy-page-container12 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-text111 {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-container13 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-text112 {
  font-size: 18px;
}
.privacy-policy-page-container14 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-text113 {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-container15 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-text114 {
  font-size: 18px;
}
.privacy-policy-page-text115 {
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-text116 {
  font-size: 18px;
}
.privacy-policy-page-text121 {
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-text122 {
  font-size: 18px;
}
.privacy-policy-page-text127 {
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-text128 {
  font-size: 18px;
}
.privacy-policy-page-container16 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-text132 {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-container17 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-text133 {
  font-size: 18px;
}
.privacy-policy-page-navlink {
  text-decoration: none;
}
.privacy-policy-page-container18 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-text138 {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-container19 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-text139 {
  font-size: 18px;
}
.privacy-policy-page-container20 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-text140 {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-page-container21 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.privacy-policy-page-text141 {
  font-size: 18px;
}
