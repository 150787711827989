.footer-footer {
  border-color: rgba(33, 33, 33, 0.1);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.footer-max-width {
  justify-content: space-between;
}
.footer-container {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-link {
  display: contents;
}
.footer-image {
  width: 206px;
  cursor: pointer;
  margin-bottom: var(--dl-space-space-twounits);
  text-decoration: none;
}
.footer-text {
  color: var(--dl-color-grays-gray100);
  max-width: 350px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.footer-container1 {
  width: 434px;
  height: 51px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.footer-text4 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-link1 {
  margin-top: 2px;
  text-decoration: none;
}
.footer-social-media {
  flex: 0 0 auto;
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text5 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-container2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.footer-icon {
  width: 24px;
  height: 24px;
  transition: 0.3s;
  margin-right: var(--dl-space-space-twounits);
}
.footer-icon:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}
.footer-icon04 {
  width: 24px;
  height: 24px;
  transition: 0.3s;
  margin-right: var(--dl-space-space-twounits);
}
.footer-icon04:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}
.footer-icon08 {
  width: 24px;
  height: 24px;
  transition: 0.3s;
  margin-right: var(--dl-space-space-twounits);
}
.footer-icon08:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}
.footer-icon10 {
  width: 24px;
  height: 24px;
  transition: 0.3s;
}
.footer-icon10:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}
.footer-links {
  flex: 0 0 auto;
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.footer-container3 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text6 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-threeunits);
}
.footer-link2 {
  margin-bottom: 30px;
  padding-bottom: 0px;
  text-decoration: none;
}
.footer-link3 {
  margin-bottom: 30px;
  text-decoration: none;
}
.footer-link4 {
  margin-bottom: 0px;
  text-decoration: none;
}
.footer-container4 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text7 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-threeunits);
}
.footer-link5 {
  display: contents;
}
.footer-image1 {
  width: 140px;
  cursor: pointer;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-link6 {
  display: contents;
}
.footer-image2 {
  width: 140px;
  cursor: pointer;
  object-fit: cover;
  text-decoration: none;
}
@media(max-width: 991px) {
  .footer-max-width {
    flex-direction: column-reverse;
  }
  .footer-container {
    width: 100%;
  }
  .footer-links {
    width: 100%;
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 479px) {
  .footer-links {
    flex-wrap: wrap;
  }
  .footer-container3 {
    width: 100%;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .footer-text6 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .footer-container4 {
    width: 100%;
  }
  .footer-text7 {
    margin-bottom: var(--dl-space-space-twounits);
  }
}
