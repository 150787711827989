.cookies-policy-page-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
}
.cookies-policy-page-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.cookies-policy-page-text {
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
}
.cookies-policy-page-container01 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.cookies-policy-page-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.cookies-policy-page-text01 {
  font-size: 18px;
}
.cookies-policy-page-container03 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.cookies-policy-page-text04 {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.cookies-policy-page-container04 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.cookies-policy-page-text05 {
  font-size: 18px;
}
.cookies-policy-page-text10 {
  font-size: 20px;
}
.cookies-policy-page-container05 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.cookies-policy-page-text11 {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.cookies-policy-page-container06 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.cookies-policy-page-text12 {
  font-size: 18px;
}
.cookies-policy-page-container07 {
  width: 100%;
  height: 81px;
  display: flex;
  align-items: flex-start;
}
.cookies-policy-page-container08 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cookies-policy-page-container09 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
}
.cookies-policy-page-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.cookies-policy-page-container11 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.cookies-policy-page-text20 {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.cookies-policy-page-container12 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.cookies-policy-page-text21 {
  font-size: 18px;
}
.cookies-policy-page-container13 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.cookies-policy-page-text50 {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.cookies-policy-page-container14 {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: flex-start;
}
.cookies-policy-page-text51 {
  font-size: 18px;
}
